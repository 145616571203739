.chips-container-row {
    display: flex;
    gap: 12px;
    justify-content: flex-start;
}

span.chips-primario {
    align-items: center;
    background: #EBF2F9;
    border: 2px solid transparent;
    border-radius: 16px;
    color: #024588;
    cursor: pointer;
    display: flex;
    font-family: 'Montserrat';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    gap: 10px;
    min-height: 36px;
    justify-content: space-between;
    line-height: normal;
    mix-blend-mode: normal;
    padding: 0 10px;
    text-align: center;
}

span.chips-primario:hover {
    background: #D7E5F4;
    border: 2px solid #024588;
}

span.chips-primario:active {
    background: #D7E5F4;
    border: 3px solid #024588;
}

span.chips-primario-disabled {
    align-items: center;
    background: #F0EFEF;
    border: 2px solid transparent;
    border-radius: 16px;
    color: #B6AFAF;
    cursor: not-allowed;
    display: flex;
    font-family: 'Montserrat';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    gap: 10px;
    min-height: 36px;
    justify-content: center;
    line-height: normal;
    mix-blend-mode: normal;
    padding: 0px 10px;
    text-align: center;
}

span.chips-primario-icono {
    align-items: center;
    background: #EBF2F9;
    border: 2px solid transparent;
    border-radius: 16px;
    color: #024588;
    cursor: pointer;
    display: flex;
    font-family: 'Montserrat';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    gap: 8px;
    min-height: 36px;
    justify-content: space-between;
    line-height: normal;
    mix-blend-mode: normal;
    padding: 0px 0px 0px 10px;
    text-align: center;
}

span.chips-primario-icono:hover {
    background: #D7E5F4;
    border: 2px solid #024588;
}

span.chips-primario-icono:active {
    background: #D7E5F4;
    border: 3px solid #024588;
}

span.chips-primario-icono-disabled {
    align-items: center;
    background: #F0EFEF;
    border: 2px solid transparent;
    border-radius: 16px;
    color: #B6AFAF;
    cursor: not-allowed;
    display: flex;
    font-family: 'Montserrat';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    gap: 10px;
    min-height: 36px;
    justify-content: center;
    line-height: normal;
    mix-blend-mode: normal;
    padding: 0px 0px 0px 10px;
    text-align: center;
}

span.chips-primario-icono-disabled svg {
    fill: #B6AFAF;
}


span.chips-secundario-icono {
    align-items: center;
    background: #E3F8E3;
    mix-blend-mode: normal;
    border: 2px solid transparent;
    border-radius: 16px;
    color: #258422;
    cursor: pointer;
    display: flex;
    font-family: 'Montserrat';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    gap: 8px;
    min-height: 36px;
    justify-content: space-between;
    line-height: normal;
    mix-blend-mode: normal;
    padding: 0px 0px 0px 10px;
    text-align: center;
}


span.chips-secundario-icono:hover {
    background: #D0F2CE;
    border: 2px solid #258422;
}

span.chips-secundario-icono:active {
    background: #D0F2CE;
    border: 3px solid #258422;
}

span.chips-secundario-icono-disabled {
    align-items: center;
    background: #F0EFEF;
    border: 2px solid transparent;
    border-radius: 16px;
    color: #B6AFAF;
    cursor: not-allowed;
    display: flex;
    font-family: 'Montserrat';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    gap: 10px;
    min-height: 36px;
    justify-content: center;
    line-height: normal;
    mix-blend-mode: normal;
    padding: 0px 0px 0px 10px;
    text-align: center;
}

span.chips-secundario-icono svg {
    fill: #258422;
}

span.chips-secundario-icono-disabled svg {
    fill: #B6AFAF;
}