hr.divisor {
    border-top: 1px solid rgba(0, 40, 70, 0.3);
    margin: 10px 0;
    width: 100%;
}

hr.divisor-vertical {
    border-left: 1px solid rgba(0, 40, 70, 0.3);
    margin: 0px 10px;
    height: 150px;
}