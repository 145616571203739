#div-buscador {
    display: flex;
    align-items: center;
    position: relative;
}

#div-buscador .icono-borrar-clase {
    display: none;
    position: absolute;
    right: 73px !important;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    z-index: 10;
}

.buscador-menu {
    display: none;
    position: absolute;
    top: 58px;
    left: 0;
    margin: 0;
    padding: 0;
    background-color: #FFFFFF;
    width: 100%;

    max-width: 322px;
    filter: drop-shadow(0px 3px 16px rgba(16, 15, 15, 0.2));
    border-radius: 10px;
}

.buscador-menu li {
    padding: 12px 20px;
    text-decoration: none;
    display: flex;
    border-radius: 0 0 0 0;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #024588;
    height: 50px;
}

.buscador-menu li:first-child {
    border-radius: 10px 10px 0 0;
    padding: 12px 20px;
    text-decoration: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #024588;
    height: 50px;
}

.buscador-menu li:last-child {
    border-radius: 0 0 10px 10px;
    padding: 12px 20px;
    text-decoration: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #024588;
    height: 50px;
}

.buscador-menu li:hover {
    background-color: #EBF2F9;
}

.buscador-menu li:active {
    background-color: #D7E5F4;
}

.buscador-menu li:first-child:hover {
    background-color: #EBF2F9;
    border-radius: 10px 10px 0 0 !important;
}

.buscador-menu li:first-child:active {
    background-color: #D7E5F4;
    border-radius: 10px 10px 0 0 !important;
}

.buscador-menu li:last-child:hover {
    background-color: #EBF2F9;
    border-radius: 0 0 10px 10px !important;
}

.buscador-menu li:last-child:active {
    background-color: #D7E5F4;
    border-radius: 0 0 10px 10px !important;
}