.insignia {
    background-color: #E33535;
    border-radius: 8px;
    height: 12px;
    mix-blend-mode: normal;
    position: relative;
    width: 12px;
}

.insignia-absolute {
    position: absolute;
    top: 8px;
    right: 6px;
}

.insignia-numero {
    align-items: center;
    background: #E33535;
    border-radius: 10px;
    color: #FFFFFF;
    display: flex;
    font-family: 'Montserrat';
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    gap: 10px;
    height: 20px;
    justify-content: center;
    line-height: 20px;
    max-width: 30px;
    padding: 0px 8px;
}

.insignia-numero-absolute {
    position: absolute;
    top: 2px;
    right: -4px;
}

.insignia-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 9px 10px;
    gap: 10px;
    height: 50px;
    background: #FFFFFF;
    mix-blend-mode: normal;
    border-radius: 10px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #024588;
}

.insignia-etiqueta {
    align-items: center;
    border-radius: 10px;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    font-family: 'Montserrat';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    gap: 10px;
    height: 20px;
    justify-content: center;
    line-height: 20px;
    mix-blend-mode: normal;
    padding: 0px 8px;
    text-transform: uppercase;
    width: fit-content;
}

.insignia-alerta {
    background: #DD7A23;
}

.insignia-estado {
    background: #27BB22;
}

.insignia-info {
    background: #3D8DDD;
}

.insignia-otros {
    background: #B257B7;
}