button.primario {
    display: flex;
    align-items: center;
    padding: 15px 28px;
    cursor: pointer;
    gap: 10px;
    background-color: #B40000;
    height: 50px;
    mix-blend-mode: normal;
    border-radius: 10px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    border: 3px solid transparent;
}

button.primario:hover {
    background-color: #E33535;
}

button.primario:active {
    border: 3px solid #B40000;
    background-color: #E33535;
}

button.disabled {
    display: flex;
    align-items: center;
    padding: 15px 28px;
    cursor: not-allowed;
    gap: 10px;
    height: 50px;
    mix-blend-mode: normal;
    border-radius: 10px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;

    background: #B6AFAF;
    border: unset;
}

button.secundario {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 15px 28px;
    gap: 10px;
    background-color: #D7E5F4;
    border: 3px solid transparent;
    height: 50px;
    mix-blend-mode: normal;
    border-radius: 10px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    justify-content: center;
    line-height: 20px;
    text-align: center;
    color: #024588;
}

button.secundario:hover {
    background-color: #BFD6ED;
}

button.secundario:active {
    background-color: #BFD6ED;
    border: 3px solid #024588;
}

button.terciario {
    align-items: center;
    background-color: unset;
    border-radius: 10px;
    border: 1px solid #024588;
    color: #024588;
    cursor: pointer;
    display: flex;
    font-family: 'Montserrat';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    gap: 10px;
    height: 50px;
    justify-content: center;
    line-height: 20px;
    mix-blend-mode: normal;
    padding: 15px 28px;
    text-align: center;
}

button.terciario:hover {
    border: 2px solid #024588;
}

button.terciario:active {
    border: 3px solid #024588;
}

button.texto {
    align-items: center;
    background-color: #FFFFFF;
    border-radius: 10px;
    border: 3px solid transparent;
    color: #024588;
    cursor: pointer;
    display: flex;
    font-family: 'Montserrat';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    gap: 10px;
    height: 50px;
    justify-content: center;
    line-height: 20px;
    mix-blend-mode: normal;
    padding: 15px 28px;
    text-align: center;
}

button.texto:hover {
    background-color: #EBF2F9;
}

button.texto:active {
    background-color: #EBF2F9;
    border: 3px solid #024588;
}

button.enlace {
    align-items: center;
    background-color: transparent;
    border: unset;
    color: #1669BC;
    cursor: pointer;
    display: flex;
    font-family: 'Montserrat';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    gap: 10px;
    height: 50px;
    line-height: 20px;
    mix-blend-mode: normal;
    text-align: center;
    text-decoration: underline;
}

button.enlace:hover {
    color: #3D8DDD;
}

button.enlace:active {
    color: #89B8E7;
}