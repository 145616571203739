:root {
    --color-principal: blue;
    --color-secundario: green;
    --color-fondo-principal: #f3f6fc;
    --color-fondo-secundario: yellow;
    --color-texto-principal: #001d35;
    --color-texto-secundario: grey;
    --tiempo-transiciones-principal: 1s;
    /* Font AIEP*/
    --aiep-font-color: #100F0F;
    /* Colores AIEP */
    /* Neutral cálido */
    --aiep-neutral-1: #363030;
    --aiep-neutral-2: #534B4B;
    --aiep-neutral-3: #766B6B;
    --aiep-neutral-4: #A59C9C;
    --aiep-neutral-5: #BEB7B7;
    --aiep-neutral-6: #CFC9C9;
    --aiep-neutral-7: #DBD7D7;
    --aiep-neutral-8: #E4E2E2;
    --aiep-neutral-9: #ECEAEA;
    --aiep-neutral-10: #F0EFEF;
    /* Primario y de Error */
    --aiep-primario-1: #5B0000;
    --aiep-primario-2: #7B0000;
    --aiep-primario-3: #B40000;
    --aiep-primario-4: #E33535;
    --aiep-primario-5: #EB6463;
    --aiep-primario-6: #F08F8E;
    --aiep-primario-7: #F5B8B7;
    --aiep-primario-8: #F8CECE;
    --aiep-primario-9: #FBE0E0;
    --aiep-primario-10: #FCEDED;
    /* Secundario y de Accesos */
    --aiep-secundario-1: #002345;
    --aiep-secundario-2: #024588;
    --aiep-secundario-3: #1669BC;
    --aiep-secundario-4: #3D8DDD;
    --aiep-secundario-5: #69A5E0;
    --aiep-secundario-6: #89B8E7;
    --aiep-secundario-7: #A3C5E6;
    --aiep-secundario-8: #BFD6ED;
    --aiep-secundario-9: #D7E5F4;
    --aiep-secundario-10: #EBF2F9;
    /* Aprobado */
    --aiep-aprobado-1: #113B0F;
    --aiep-aprobado-2: #195917;
    --aiep-aprobado-3: #258422;
    --aiep-aprobado-4: #27BB22;
    --aiep-aprobado-5: #4DD049;
    --aiep-aprobado-6: #6DD869;
    --aiep-aprobado-7: #8FE18C;
    --aiep-aprobado-8: #B8ECB6;
    --aiep-aprobado-9: #D0F2CE;
    --aiep-aprobado-10: #E3F8E3;
    /* Etiqueta */
    --aiep-etiqueta-1: #451845;
    --aiep-etiqueta-2: #6D2D6E;
    --aiep-etiqueta-3: #954B96;
    --aiep-etiqueta-4: #AC4EAD;
    --aiep-etiqueta-5: #BC5CD4;
    --aiep-etiqueta-6: #CA86DB;
    --aiep-etiqueta-7: #CA86DB;
    --aiep-etiqueta-8: #DCB9E5;
    --aiep-etiqueta-9: #ECDAF1;
    --aiep-etiqueta-10: #F3E3F8;


}