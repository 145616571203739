.form-column {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
}

.form-column .form-input {
    display: flex;
    justify-content: flex-start;
    position: relative;
}

.form-column label {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #024588;
}

.form-column label.disabled {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #B6AFAF;
}

.form-column input {
    box-sizing: border-box;
    padding: 15px 40px 15px 20px;
    height: 50px;
    background: #FAF9F9;
    mix-blend-mode: normal;
    border: 1px solid #024588;
    border-radius: 10px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #024588;
    box-shadow: unset;
    outline: unset;
}

.form-column input.disabled {
    border: 1px solid #B6AFAF;
    color: #B6AFAF;
    cursor: not-allowed;
}

.form-column input::placeholder {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #B6AFAF;
}

.form-column input:hover {
    border: 2px solid #024588;
}

.form-column input.disabled:hover {
    border: 1px solid #B6AFAF;
}

.form-column input:focus,
.form-column input:active,
.form-column input:focus-visible,
.form-column input:target {
    border: 3px solid #3D8DDD;
}

.form-column .icono-borrar-clase {
    display: none;
    position: absolute;
    right: 8px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    z-index: 10;
    top: 8px;
}

.icono-verificar-clase {
    z-index: 1;
    display: none;
    position: absolute;
    right: 8px;
    transition: 0.3s ease-in-out;
    top: 8px;
}

.form-error {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #E33535;
}