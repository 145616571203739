div.snackbar {
    align-items: center;
    background: #002846;
    border-radius: 10px;
    box-shadow: 0px 3px 10px rgba(16, 15, 15, 0.4);
    color: #EBF2F9;
    display: flex;
    font-family: 'Montserrat';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    gap: 10px;
    line-height: 26px;
    max-width: 550px;
    min-width: 320px;
    mix-blend-mode: normal;
    padding: 21px 20px;
    width: 100%;
    z-index: 10;
}

div.snackbar-vertical {
    background: #002846;
    border-radius: 10px;
    box-shadow: 0px 3px 10px rgba(16, 15, 15, 0.4);
    color: #EBF2F9;
    display: flex;
    flex-direction: column;
    font-family: 'Montserrat';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    gap: 40px;
    line-height: 26px;
    max-width: 550px;
    min-width: 320px;
    mix-blend-mode: normal;
    padding: 21px 20px;
    width: 100%;
    z-index: 10;
}

div.snackbar-vertical h4 {
    color: #EBF2F9;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
}

div.snackbar-vertical p {
    color: #EBF2F9;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
}

div.snackbar-link {
    align-items: center;
    background: #002846;
    border-radius: 10px;
    box-shadow: 0px 3px 10px rgba(16, 15, 15, 0.4);
    color: #EBF2F9;
    display: flex;
    font-family: 'Montserrat';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    gap: 10px;
    justify-content: space-between;
    line-height: 26px;
    max-width: 520px;
    min-width: 280px;
    mix-blend-mode: normal;
    padding: 21px 20px;
    width: 100%;
    z-index: 10;
}

div.snackbar-link span,
div.snackbar-vertical span {
    align-items: center;
    display: flex;
    gap: 10px;
}

div.snackbar-link a {
    color: #A3C5E6;
    font-family: 'Montserrat';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    font-weight: 600;
    gap: 10px;
    line-height: 26px;
    mix-blend-mode: normal;
    mix-blend-mode: normal;
    text-align: center;
    text-decoration-line: underline;
}

div.snackbar-vertical a {
    align-self: flex-end;
    color: #A3C5E6;
    font-family: 'Montserrat';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    font-weight: 600;
    gap: 10px;
    line-height: 26px;
    mix-blend-mode: normal;
    mix-blend-mode: normal;
    text-align: center;
    text-decoration-line: underline;
}

div.snackbar-link a:hover,
div.snackbar-vertical a:hover {
    color: #BFD6ED;
}

div.snackbar-link a:active,
div.snackbar-vertical a:active {
    color: #EBF2F9;
}

div.snackbar-vertical .fila {
    display: flex;
    gap: 10px;
}

div.snackbar-vertical .columna {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

div.snackbar-vertical .columna-small {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

#snackbar-ejemplo,
#snackbar-ejemplo1,
#snackbar-ejemplo2,
#snackbar-ejemplo3,
#snackbar-ejemplo4,
#snackbar-ejemplo5 {
    position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translate(-50%, 500px);
    transition: transform 0.3s ease-in-out;
}

.snackbar.show,
.snackbar-link.show,
.snackbar-vertical.show {
    transform: translate(-50%, 0) !important;
}