.bottom-sheet {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 10px 10px 30px;
    gap: 10px;
    position: fixed;
    bottom: 0;
    left: 50%;
    background-color: white;
    transform: translate(-50%, 100%);
    transition: transform 0.3s ease-in-out;
    width: 300px;
    height: 429px;
    background: #FFFFFF;
    border-radius: 10px 10px 0px 0px;
    z-index: 99;
}

.bottom-sheet .barra {
    width: 100px;
    border-top: 5px solid #D7E5F4;
    background: #D7E5F4;
    border-radius: 20px;
    cursor: pointer;
}

.bottom-sheet.show {
    transform: translate(-50%, 0);
}

.bottom-sheet .list-icono-horizontal {
    display: flex;
    gap: 10px;
}

.bottom-sheet .list-icono-horizontal .icon-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 9px 10px;

    width: 52px;
    height: 50px;

    background: #FFFFFF;
    mix-blend-mode: normal;
    border-radius: 10px;
}

.bottom-sheet hr {
    border: 1px solid rgba(0, 40, 70, 0.3);
    width: 100%;

}

.bottom-sheet .lista {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 9px 10px;
    height: 50px;
    background: #FFFFFF;
    mix-blend-mode: normal;
    border-radius: 10px;
    width: 100%;
}


.bottom-sheet .lista .elemento {
    display: flex;
    width: 100%;
    height: 50px;
    align-items: center;
    padding: 9px 10px;
    justify-content: space-between;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #024588;
}