.dialogo {
    display: flex;
    flex-direction: column;
    padding: 40px;
    gap: 40px;
    height: fit-content;
    background: #FFFFFF;
    border-radius: 10px;
    width: 500px;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%);
    transition: transform 0.3s ease-in-out;
    position: fixed;
    z-index: 9999;
}

.dialogo .dialogo-cuerpo,
.dialogo-movil .dialogo-cuerpo {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.dialogo.show {
    transform: translate(-50%, -80%);
}

.dialogo-movil.show {
    transform: translate(-50%, -60%);
}

.dialogo h4,
.dialogo-movil h4 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    color: #002846;
}

.dialogo p,
.dialogo-movil p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #002846;
}

.grupo-botones {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
}

@media (max-width: 600px) {
    .dialogo {
        border-radius: 10px 10px 0 0;
        width: 100%;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 100%);
        transition: transform 0.3s ease-in-out;
        position: fixed;
    }

    .grupo-botones {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
        gap: 20px;
    }

    .grupo-botones button {
        width: 100% !important;
        justify-content: center;
    }

    .grupo-botones .primario {
        order: 1;
    }

    .grupo-botones .terciario {
        order: 2;
    }

    .dialogo.show {
        transform: translate(-50%, 0);
    }
}

.dialogo-movil {
    display: flex;
    height: fit-content;
    flex-direction: column;
    padding: 40px;
    gap: 40px;
    background: #FFFFFF;
    border-radius: 10px 10px 0 0;
    width: 320px;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%);
    transition: transform 0.3s ease-in-out;
    z-index: 9999;
}

.grupo-botones-movil {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
}

.grupo-botones-movil {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 20px;
}

.grupo-botones-movil button {
    width: 100% !important;
    justify-content: center;
}

.grupo-botones-movil .primario {
    order: 1;
}

.grupo-botones-movil .terciario {
    order: 2;
}