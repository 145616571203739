#main-menu {
    height: 100vh;
    min-width: 200px;
    padding-bottom: 15px;
    background-color: #FFFFFF;
    border-right: 1px solid #D7E5F4;
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: space-between;
    flex-direction: column;
    overflow: hidden;
    z-index: 999;
}

nav ul {
    list-style-type: none;
    margin: 0 !important;
    background: #FFFFFF;
    padding: 0px 0px 0px;
    z-index: 999;
}

nav ul li {
    padding: 2px 10px;
}

nav ul li a {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-decoration: none;
    text-align: center;
    color: #024588;
    mix-blend-mode: normal;
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 9px 10px 9px 10px;
    border-radius: 10px;
}

nav ul li a:hover {
    background: #EBF2F9;
}

nav .active {
    background: #D7E5F4;
}

nav ul ul {
    border-left: 1px solid #D7E5F4;
    width: 200px;
    height: 100vh;
    max-height: 100vh;
    overflow: auto;
    visibility: hidden;
    opacity: 0;
    transition: 0.001s ease-in-out;
    -webkit-transition: 0.001s ease-in-out;
    position: fixed;
    top: 0;
    left: 0;
    padding: 20px 0px 72px !important;
    z-index: 999;
}

nav ul li:hover>ul {
    visibility: visible;
    left: 198px;
    /* Al alterar las márgenes y el height no es necesaria esta propiedad */
    opacity: 1;
    transition: opacity 0.5s linear;
}

nav ul ul li {
    display: block;
    margin: 0;
    padding: 2px 10px;
}

nav ul ul li a {
    text-align: left;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #024588;
    text-decoration: none;
    padding: 15px 10px;
}

nav ul ul li a:hover {
    background-color: #EBF2F9;
}