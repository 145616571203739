.radio-group,
.checkbox-group {
    position: relative;
}

.radio-group .checkbox-radio,
.checkbox-group .checkbox {
    font-family: 'Montserrat';
    position: relative;
    color: #024588;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    cursor: pointer;
    width: 170px;
    display: flex;
    align-items: center;
    padding: 9px 20px 9px 10px;
    gap: 10px;
    height: 50px;
    background: #FFFFFF;
    mix-blend-mode: normal;
    border-radius: 0px;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
}

.radio-group .checkbox-radio:first-child,
.checkbox-group .checkbox:first-child {
    border-radius: 10px 10px 0 0 !important;
}

.radio-group .checkbox-radio:last-child,
.checkbox-group .checkbox:last-child {
    border-radius: 0 0 10px 10px !important;
}

.check-box-activo {
    background: #D7E5F4 !important;
}

input.input-checkbox-radio {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

input.input-checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkbox-radio .radio-img-lleno,
.checkbox .checkbox-img-lleno {
    display: none;
}

.radio-group .checkbox-radio:first-child,
.checkbox-group .checkbox:first-child {
    border-radius: 10px 10px 0 0 !important;
}

.radio-group .checkbox-radio:last-child,
.checkbox-group .checkbox:last-child {
    border-radius: 0 0 10px 10px !important;
}