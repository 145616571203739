.switch-container {
    background: transparent;
    padding: 9px 10px;
    border-radius: 30px;
}

.switch-container:hover {
    background: #EBF2F9;
}

.switch-container:active {
    background: #D7E5F4;
}

.switch {
    position: relative;
    display: inline-block;
    width: 62px;
    height: 36px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #FFFFFF;
    -webkit-transition: .4s;
    transition: .4s;
    border: 1px solid #024588;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: #A3C5E6;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #FFFFFF;
}

input:checked+.slider:before {
    background-color: #27BB22;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}


.slider-disabled {
    position: absolute;
    cursor: not-allowed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #FFFFFF;
    -webkit-transition: .4s;
    transition: .4s;
    border: 1px solid #B6AFAF;
}

.slider-disabled:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: #B6AFAF;
    -webkit-transition: .4s;
    transition: .4s;
}

/* Rounded sliders */
.slider-disabled.round {
    border-radius: 34px;
}

.slider-disabled.round:before {
    border-radius: 50%;
}