.card-container {
    display: flex;
    margin-bottom: 35px;
    gap: 25px;
    width: 100%;
    flex-wrap: wrap;
}

.card {
    display: flex;
    flex-direction: column;
    padding: 20px 20px 30px;
    gap: 30px;
    width: 450px;
    background: #FFFFFF;
    mix-blend-mode: normal;
    border-radius: 10px;
    transition: box-shadow 200ms;
    -webkit-transition: box-shadow 200ms;
}

.card:last-child {
    display: flex;
    flex-direction: column;
    padding: 20px 20px 30px;
    gap: 30px;
    width: 450px;
    background: #FFFFFF;
    mix-blend-mode: normal;
    border-radius: 10px;
    transition: box-shadow 200ms;
    -webkit-transition: box-shadow 200ms;
    margin-bottom: 25px;
}

.card:hover {
    box-shadow: 0px 3px 16px rgba(16, 15, 15, 0.2);
}

.card:active {
    box-shadow: 0px 3px 16px rgba(16, 15, 15, 0.2);
}

.card .card-body {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.card .card-body img {
    height: 190px;
}

.card .card-body h2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    color: #024588;
    mix-blend-mode: normal;
}

.card .card-body p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #024588;
    mix-blend-mode: normal;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.card .card-actions {
    display: flex;
    justify-content: flex-end;
}

.card-pequena {
    display: flex;
    flex-direction: column;
    padding: 20px 20px 30px;
    gap: 30px;
    width: 360px;
    /* height: 437px; */
    background: #FFFFFF;
    mix-blend-mode: normal;
    border-radius: 10px;
    transition: box-shadow 200ms;
    -webkit-transition: box-shadow 200ms;
}


.card-pequena:hover {
    box-shadow: 0px 3px 16px rgba(16, 15, 15, 0.2);
}

.card-pequena:active {
    box-shadow: 0px 3px 16px rgba(16, 15, 15, 0.2);
}

.card-pequena .card-body {
    display: flex;
    flex-direction: column;
    gap: 16px;

}

.card-pequena .card-body img {
    height: auto;
    max-height: 190px;
}

.card-pequena .card-body h2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    color: #024588;
    mix-blend-mode: normal;
}

.card-pequena .card-body p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #024588;
    mix-blend-mode: normal;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.card-pequena .card-actions {
    display: flex;
    justify-content: flex-end;
}


.card-secundaria {
    display: flex;
    flex-direction: column;
    padding: 20px 20px 30px;
    gap: 30px;
    width: 360px;
    background: #FFFFFF;
    mix-blend-mode: normal;
    border-radius: 10px;
    transition: box-shadow 200ms;
    -webkit-transition: box-shadow 200ms;
}

.card-secundaria:hover {
    box-shadow: 0px 3px 16px rgba(16, 15, 15, 0.2);
}

.card-secundaria:active {
    box-shadow: 0px 3px 16px rgba(16, 15, 15, 0.2);
}

.card-secundaria .card-body {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.card-secundaria .card-body img {
    height: auto;
    max-height: 190px;
}

.card-secundaria .card-body h2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    color: #024588;
    mix-blend-mode: normal;
}

.card-secundaria .card-body p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #024588;
    mix-blend-mode: normal;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.card-secundaria .card-actions {
    display: flex;
    justify-content: center;
    width: 100%;
}

.card-secundaria .card-actions button {
    width: 100%;
}


.card-horizontal {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    padding: 20px;
    gap: 20px;
    width: 400px;
    height: auto;
    background: #FFFFFF;
    mix-blend-mode: normal;
    border-radius: 10px;
    transition: box-shadow 200ms;
    -webkit-transition: box-shadow 200ms;
}


.card-horizontal:hover {
    box-shadow: 0px 3px 16px rgba(16, 15, 15, 0.2);
}

.card-horizontal:active {
    box-shadow: 0px 3px 16px rgba(16, 15, 15, 0.2);
}

.card-horizontal .imagen {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    height: 104px !important;
    width: 110px !important;
    min-width: 110px !important;
    background-color: #FCEDED;
    border-radius: 5px;
    padding: 9px 10px;
    max-width: unset !important;
}

.card-horizontal .imagen img {
    height: 70px !important;
    width: 70px !important;
}

.card-horizontal .card-body {
    display: flex;
    flex-direction: column;
    gap: 16px;

}

.card-horizontal .card-body h2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #024588;
    mix-blend-mode: normal;
}

.card-horizontal .card-body p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #024588;
    mix-blend-mode: normal;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}


.card-secundaria-small {
    display: flex;
    flex-direction: column;
    padding: 20px 20px 30px;
    gap: 30px;
    width: 186px;
    background: #FFFFFF;
    mix-blend-mode: normal;
    border-radius: 10px;
    transition: box-shadow 200ms;
    -webkit-transition: box-shadow 200ms;
}


.card-secundaria-small:hover {
    box-shadow: 0px 3px 16px rgba(16, 15, 15, 0.2);
}

.card-secundaria-small:active {
    box-shadow: 0px 3px 16px rgba(16, 15, 15, 0.2);
}

.card-secundaria-small .card-body {
    display: flex;
    flex-direction: column;
    gap: 16px;

}

.card-secundaria-small .card-body h2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #024588;
    mix-blend-mode: normal;
}

.card-secundaria-small .card-body p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #024588;
    mix-blend-mode: normal;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.card-secundaria-small .card-actions {
    display: flex;
    justify-content: flex-end;
    display: flex;
    justify-content: center;

}