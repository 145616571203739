.hojas-laterales {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 10px 10px 30px;
    gap: 10px;
    position: fixed;
    height: 100vh;
    top: 0;
    right: 0;
    background-color: white;
    transform: translateX(550px);
    transition: transform 0.25s ease-in-out;
    width: 300px;
    background: #FFFFFF;
    z-index: 99;
}


.hojas-laterales.show {
    transform: translateX(0);
}

.hojas-laterales .list-horizontal {
    display: flex;
    gap: 10px;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    height: 50px;
    padding: 9px 10px;
}


.hojas-laterales .list-horizontal div {
    display: flex;
    gap: 10px;
    width: 100%;
    align-items: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #024588;
}


.hojas-laterales .list-icono-horizontal {
    display: flex;
    gap: 10px;
}

.hojas-laterales .list-icono-horizontal .icon-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 9px 10px;

    width: 52px;
    height: 50px;

    background: #FFFFFF;
    mix-blend-mode: normal;
    border-radius: 10px;
}

.hojas-laterales .lista {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 9px 10px;
    height: 50px;
    background: #FFFFFF;
    mix-blend-mode: normal;
    border-radius: 10px;
    width: 100%;
}


.hojas-laterales .lista .elemento {
    display: flex;
    width: 100%;
    height: 50px;
    align-items: center;
    padding: 9px 10px;
    justify-content: space-between;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #024588;
}