.codigo-elemento {
    background-color: var(--ds-surface, #FFFFFF);
    background-image: linear-gradient(45deg, var(--ds-surface-sunken, #f9f9fa) 25%, transparent 25%), linear-gradient(135deg, var(--ds-surface-sunken, #f9f9fa) 25%, transparent 25%), linear-gradient(45deg, transparent 75%, var(--ds-surface-sunken, #f9f9fa) 75%), linear-gradient(135deg, transparent 75%, var(--ds-surface-sunken, #f9f9fa) 75%);
    border: 1px solid lightgray;
    display: flex;
    gap: 30px;
    border-radius: 12px 12px 0 0;
    padding: 36px 44px;
    background-size: 20px 20px;
    background-position: 0px 0px, 10px 0px, 10px -10px, 0px 10px;
    width: 100%;
    overflow-x: scroll;
    flex-wrap: wrap;
}

.codigo-text {
    border: 1px solid lightgray;
    border-radius: 0 0 12px 12px;
    padding: 0;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 25px;
    max-height: auto;
    width: 100%;
    background-color: #D7E5F4;
    color: #002846;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    height: 300px;
    overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
}

.codigo-text .codigo-html,
.codigo-text .codigo-css,
.codigo-text .codigo-js {
    position: relative;
    background: transparent;
    width: 33.33%;
    overflow-y: scroll;
    border-right: 1px solid grey;
    padding: 10px 0 0 15px;
}

.icono-copiar-pegar {
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
}

.icono-copiar-pegar:hover {
    transform: scale(1.05);
}

.codigo-text .codigo-html h4,
.codigo-text .codigo-css h4,
.codigo-text .codigo-js h4 {
    margin-bottom: 10px;
}



.codigo-text .css-title {
    color: #024588;
    font-weight: 600;
}

.codigo-text .css-body-field {
    color: #26c6da;
}

.codigo-text .css-body-value {
    color: #fff59d;
}


button.primario {
    align-items: center;
    background-color: #B40000;
    border-radius: 10px;
    border: 3px solid transparent;
    color: #FFFFFF;
    cursor: pointer;
    display: flex;
    font-family: 'Montserrat';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    gap: 10px;
    height: 50px;
    line-height: 20px;
    padding: 15px 28px;
    text-align: center;
}

button.primario:hover {
    background-color: #E33535;
}

button.primario:active {
    background-color: #E33535;
    border: 3px solid #B40000;
}

button.primario:disabled {
    background: #B6AFAF;
    border: unset;
}