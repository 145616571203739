.rastreador-progreso-vertical {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
    width: 260px;
    background-color: white;
}

.rastreador-progreso-vertical .contenedor {
    display: flex;
    gap: 20px;
}

.rastreador-progreso-vertical .elemento {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    height: 106px;
}

.rastreador-progreso-vertical .elemento p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #024588;
}

.rastreador-progreso-vertical .elemento .disabled {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #B6AFAF;
}

.rastreador-progreso-vertical .barra-vertical-primero {
    border-radius: 10px 10px 0px 0px;
    width: 6px;
    height: 106px;
}

.rastreador-progreso-vertical .barra-vertical-ultimo {
    border-radius: 0px 0px 10px 10px;
    width: 6px;
    height: 106px;
}

.rastreador-progreso-vertical .barra-vertical {
    width: 6px;
    height: 106px;
}

.rastreador-progreso-vertical .completado {
    background-color: #27BB22;
}

.rastreador-progreso-vertical .en-progreso {
    background-color: #3D8DDD;
}

.rastreador-progreso-vertical .pendiente {
    background-color: #D7E5F4;
}

/* HORIZONTAL */
/* HORIZONTAL */
/* HORIZONTAL */
.rastreador-progreso-horizontal {
    display: flex;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
    background-color: white;

}

.rastreador-progreso-horizontal .contenedor {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.rastreador-progreso-horizontal .elemento {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    height: 140px;
    width: 180px;
}

.rastreador-progreso-horizontal .elemento p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    width: 95%;
    color: #024588;
}

.rastreador-progreso-horizontal .elemento .disabled {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #B6AFAF;
}

.rastreador-progreso-horizontal .barra-horizontal-primero {
    border-radius: 10px 0px 0px 10px;
    width: 180px;
    height: 6px;
}

.rastreador-progreso-horizontal .barra-horizontal-ultimo {
    border-radius: 0px 10px 10px 0px;
    width: 180px;
    height: 6px;
}

.rastreador-progreso-horizontal .barra-horizontal {
    width: 180px;
    height: 6px;
}

.rastreador-progreso-horizontal .completado {
    background-color: #27BB22;
}

.rastreador-progreso-horizontal .en-progreso {
    border-top: 6px solid #3D8DDD;
}

.rastreador-progreso-horizontal .pendiente {
    border-top: 6px solid #D7E5F4;
}