.botones-segmentados {
    align-items: flex-start;
    display: flex;
    padding: 0px;
}

.botones-segmentados button:first-child {
    border-radius: 10px 0px 0px 10px;
}

.botones-segmentados button:last-child {
    border-radius: 0px 10px 10px 0px;
}

.botones-segmentados button {
    align-items: center;
    background-color: white;
    border-radius: 0;
    border: 1px solid #024588;
    color: #024588;
    cursor: pointer;
    display: flex;
    font-family: 'Montserrat';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    gap: 10px;
    height: 40px;
    justify-content: center;
    line-height: 26px;
    mix-blend-mode: normal;
    padding: 7px 28px;
    text-align: center;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
}

.botones-segmentados button:hover {
    background: #EBF2F9;
    border: 2px solid #024588;
}

.botones-segmentados button.grupo-btn-active {
    background: #D7E5F4;
    border: 3px solid #024588;
}