@import url('https://fonts.googleapis.com/css?family=Rajdhani:300,400,500,600,700');
        @import url('https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');
        @import url('https://fonts.googleapis.com/css?family=Raleway:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');
        @import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,600i,700,700i,800,800i&display=swap');

        @import url('https://fonts.googleapis.com/css?family=Bitter');
        
        body {
            font-family: "Bitter","serif","Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
        }

        h1, h2, h3, h4, h5, h6 {
            letter-spacing: 0;
            font-weight: normal;
            font-family: "Bitter","serif","Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
            position: relative;
            padding: 0;
            font-weight: normal;
            line-height: normal;
            color: #111111;
            margin: 0;
        }

        /* ul, li, ol {
            margin: 0px;
            padding: 0px;
            list-style: none;
        } */
        a, .btn {
            text-decoration: none !important;
            outline: none !important;
            -webkit-transition: all .3s ease-in-out;
            -moz-transition: all .3s ease-in-out;
            -ms-transition: all .3s ease-in-out;
            -o-transition: all .3s ease-in-out;
            transition: all .3s ease-in-out;
        }
         #footer {
            margin: 0;
            background: #151515;
            padding: 35px 0;
        }

         #footer a {
            border-bottom: none;
         }

        .footer-col-left {
            width: 80%;
        }

        .footer-col-right {
            width: 20%;
        }

        .copyright p {
            margin-left: 40px !important;
        }

         #widget {
            background: #002346;
            padding: 10px;
            margin-top: 20px;
         }

        .informa {
            display: inline-block;
            padding: 30px;
            vertical-align: top;
            width: 24%;
        }

         .informa li {
            font-size: 17px;
            line-height: 37px;
            color: #ffffff;
        }

        .informa ul li a {
            color: #fff;
            font-size: 17px;
            line-height: 30px;
        }

        .informa img {
            margin-left: auto;
            margin-right: auto;
        }

        .text-center {
            text-align: center;
        }

        .copyright p, .copyright a {
            color: #bdbdbe !important;
            font-weight: 400 !important;
            border-bottom: none;
        }

        ul.social_icon {
            display: inline-flex;
        }

        ul.social_icon li a i {
            color: #fff;
            display: inline-block;
            text-align: center;
            line-height: 33px;
            font-size: 28px;
            font-weight: bold;
            padding: 0 10px;
        }

        .titlecontainer {
            position: relative;
        }

        .titlepage {
            margin: 40px;
        }

        .titlepage h2 {
            font-size: 45px;
            font-weight: bold;
            line-height: 50px;
            color: #002352;
            padding: 5px;
        }

        .titlepage::before {
            content: "";
            position: absolute;
            border: #002352 solid 3px;
            border-width: 3px;
            width: 80px;
            z-index: 999;
            margin: 0 auto;
            left: 0;
            right: 0;
            top: 58px;
        }

        .card-container {
            display: flex;
            margin-bottom: 35px;
            gap: 25px;
            width: 1150px;
            flex-wrap: wrap;
            margin: 0 auto;
        }

        .card-body h3 {
            color: #002346;
            font-size: 22px;
            font-weight: bold;
            line-height: 25px;
            text-transform: uppercase;
            padding-top: 25px;
        }

        .card-body p {
            color: #292929;
            padding-top: 25px;
            display: block;
            padding-bottom: 40px;
        }

        .card-secundaria .card-body p {
            display: block;
        }