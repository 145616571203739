.icono-box {
    width: 102px;
    height: 150px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 30px 10px;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    margin-bottom: 15px;
    border: 3px solid white;
}

.icono-box:hover {
    background: #EBF2F9;
    border: 3px solid #EBF2F9;
}

.icono-box:active {
    background: #EBF2F9;
    border: 3px solid #024588;
}

.icono-box p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    text-align: center;
    color: #024588;
    mix-blend-mode: normal;
}