::-webkit-scrollbar {
    -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
    width: 8px;
}

::-webkit-scrollbar-button:increment,
.contenedor::-webkit-scrollbar-button {
    display: none;
}

::-webkit-scrollbar:horizontal {
    height: 8px;
}

::-webkit-scrollbar-thumb {
    background-color: #797979c3;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
}

::-webkit-scrollbar-track {
    border-radius: 10px;
}