button.fab-icono {
    align-items: center;
    background: #024588;
    border-radius: 30px;
    border: 3px solid transparent;
    box-shadow: 0px 3px 16px rgba(16, 15, 15, 0.2);
    cursor: pointer;
    display: flex;
    height: 60px;
    justify-content: center;
    mix-blend-mode: normal;
    padding: 9px;
    width: 60px;
}

button.fab-texto-icono {
    align-items: center;
    background: #024588;
    border-radius: 30px;
    border: 3px solid transparent;
    box-shadow: 0px 3px 16px rgba(16, 15, 15, 0.2);
    color: #FFFFFF;
    cursor: pointer;
    display: flex;
    font-family: 'Montserrat';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    gap: 10px;
    height: 60px;
    justify-content: center;
    line-height: 20px;
    mix-blend-mode: normal;
    padding: 9px 28px;
    text-align: center;
}

button.fab-icono .icono-lineal,
button.fab-texto-icono .icono-lineal {
    display: block;
}

button.fab-icono .icono-lleno,
button.fab-texto-icono .icono-lleno {
    display: none;
}

button.fab-icono .icono-lineal,
button.fab-texto-icono .icono-lineal {
    fill: #ffffff;
}

button.fab-icono .icono-lleno,
button.fab-texto-icono .icono-lleno {
    fill: transparent;
}

button.fab-icono:hover,
button.fab-texto-icono:hover {
    background-color: #1669BC;
}

button.fab-icono:active,
button.fab-texto-icono:active {
    background-color: #1669BC;
    border: 3px solid #024588;
}

button.fab-icono:active .icono-lineal,
button.fab-texto-icono:active .icono-lineal {
    display: none;
}

button.fab-icono:active .icono-lleno,
button.fab-texto-icono:active .icono-lleno {
    display: block;
}

button.fab-icono-secundario {
    align-items: center;
    background: #FFFFFF;
    border-radius: 30px;
    border: 3px solid transparent;
    box-shadow: 0px 3px 16px rgba(16, 15, 15, 0.2);
    cursor: pointer;
    display: flex;
    height: 60px;
    justify-content: center;
    mix-blend-mode: normal;
    width: 60px;
}

button.fab-icono-secundario svg {
    fill: #024588;
}

button.fab-icono-secundario:hover {
    background: #EBF2F9;
}

button.fab-icono-secundario:active {
    background: #EBF2F9;
    border: 3px solid #024588;
}

.fab-icono-secundario-menu {
    cursor: pointer;
    align-items: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid transparent;
    width: 60px;
    height: 60px;
    background: #FFFFFF;
    mix-blend-mode: normal;
    /* Sombra Inferior */
    box-shadow: 0px 3px 16px rgba(16, 15, 15, 0.2);
    border-radius: 30px;
}

.fab-icono-secundario-menu svg {
    fill: #024588;
}

.fab-icono-secundario-menu:hover {
    background: #EBF2F9;
}

.fab-icono-secundario-menu:active {
    background: #EBF2F9;
    border: 3px solid #024588;
}

.fab-icono-secundario-menu:focus {
    background: #EBF2F9;
    border: 3px solid #024588;
}

input.fab-checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.fab-menu {
    display: none;
    position: absolute;
    top: 58px;
    left: 0;
    margin: 0;
    padding: 0;
    background-color: #FFFFFF;
    width: 100%;
    min-width: 200px;
    max-width: 240px;
    filter: drop-shadow(0px 3px 16px rgba(16, 15, 15, 0.2));
    border-radius: 10px;
}

.fab-menu li:first-child {
    border-radius: 10px 10px 0 0;
}

.fab-menu li:last-child {
    border-radius: 0 0 10px 10px;
}

.fab-menu li {
    padding: 9px 20px;
    text-decoration: none;
    display: flex;
    border-radius: 0 0 0 0 !important;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #024588;
    cursor: pointer;
}


.fab-menu li:hover {
    background-color: #EBF2F9;
}

.fab-menu li:first-child:hover {
    background-color: #EBF2F9;
    border-radius: 10px 10px 0 0 !important;
}

.fab-menu li:last-child:hover {
    background-color: #EBF2F9;
    border-radius: 0 0 10px 10px !important;
}

.fab-menu li:active {
    background-color: #D7E5F4;
}

.fab-menu li:first-child:active {
    background-color: #D7E5F4;
    border-radius: 10px 10px 0 0 !important;
}

.fab-menu li:last-child:active {
    background-color: #D7E5F4;
    border-radius: 0 0 10px 10px !important;
}


.fab-checkbox:checked+.fab-menu {
    display: block;
}

/* input[type="checkbox"]:checked+label {
    background: red !important;
    border: 3px solid #024588 !important;
} */


.fab-secundario-menu {
    align-items: center;
    background: #FFFFFF;
    border-radius: 30px;
    border: 3px solid transparent;
    box-shadow: 0px 3px 16px rgba(16, 15, 15, 0.2);
    color: #024588;
    cursor: pointer;
    display: flex;
    font-family: 'Montserrat';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    gap: 10px;
    height: 60px;
    justify-content: center;
    line-height: 20px;
    mix-blend-mode: normal;
    padding: 9px 28px;
}

.fab-secundario-menu svg {
    fill: #024588;
}

.fab-secundario-menu:hover {
    background: #EBF2F9;
}

.fab-secundario-activo {
    background: #EBF2F9;
    border: 3px solid #024588;
}