ul.listas {}

ul.listas .item-simple {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
    padding: 15px 20px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #024588;
    height: 56px;
    min-width: 280px;
    max-width: 320px;
    background: #FFFFFF;
    mix-blend-mode: normal;
    border-bottom: 1px solid rgba(0, 40, 70, 0.3);
}

ul.listas .item-simple .cuerpo-horizontal {
    display: flex;
    gap: 10px;
    align-items: center;
}

ul.listas .item-doble {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
    padding: 12px 20px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #024588;
    min-width: 400px;
    max-width: 400px;
    background: #FFFFFF;
    mix-blend-mode: normal;
    border-bottom: 1px solid rgba(0, 40, 70, 0.3);
}

ul.listas .item-doble .cuerpo-horizontal {
    display: flex;
    gap: 10px;
    align-items: center;
}

ul.listas .item-doble .cuerpo-vertical {
    display: flex;
    flex-direction: column;
    gap: 0px;
    align-items: flex-start;
}

ul.listas .item-doble .cuerpo-vertical h4 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #024588;
}

ul.listas .item-doble .cuerpo-vertical p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #024588;
    max-width: 200px;
}

ul.listas .item-doble .cuerpo-vertical .p-1-linea {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

ul.listas .item-doble .cuerpo-vertical .p-extenso {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #024588;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}