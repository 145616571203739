h1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-size: 66px;
    line-height: 73px;
    color: #002846;
    mix-blend-mode: normal;
    margin: 30px 0;
}

p.texto-titulo {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #002846;
    mix-blend-mode: normal;
}