input.btn-icono-checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

button.btn-icono {
    align-items: center;
    background: #FFFFFF;
    border-radius: 10px;
    border: 3px solid transparent;
    cursor: pointer;
    display: flex;
    gap: 10px;
    height: 50px;
    justify-content: center;
    min-width: 52px;
    mix-blend-mode: normal;
    padding: 9px 10px;
    position: relative;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
}

button.btn-icono:hover {
    background: #EBF2F9;
}

button.btn-icono:active {
    background: #EBF2F9;
    border: 3px solid #024588;
}

button.activo {
    background: #EBF2F9;
    border: 3px solid #024588;
}


button.btn-icono svg {
    fill: #024588;
}

button.btn-icono.disabled {
    cursor: not-allowed;
    align-items: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px 10px;
    gap: 10px;
    border: 3px solid transparent;
    width: 52px;
    height: 50px;
    background: #F0EFEF;
    mix-blend-mode: normal;
    border-radius: 10px;
}


button.btn-icono.disabled svg {
    fill: #B6AFAF;
}


label.btn-icono-select {
    align-items: center;
    background: #FFFFFF;
    border-radius: 10px;
    border: 3px solid transparent;
    color: #024588;
    cursor: pointer;
    display: flex;
    font-family: 'Montserrat';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    gap: 10px;
    height: 50px;
    justify-content: center;
    line-height: 26px;
    mix-blend-mode: normal;
    padding: 9px 10px;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
}

label.btn-icono-select:hover {
    background: #EBF2F9;
}

label.btn-icono-activar {
    background: #EBF2F9;
    border: 3px solid #024588;
}

.flecha-abajo {
    display: block;
}

.flecha-arriba {
    display: none;
}

.esconder {
    display: none;
}

.mostrar {
    display: block !important;
}

label.btn-icono-select.disabled {
    cursor: not-allowed;
    align-items: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px 10px;
    gap: 10px;
    border: 3px solid transparent;
    width: 52px;
    height: 50px;
    background: #F0EFEF;
    mix-blend-mode: normal;
    box-shadow: 0px 3px 16px rgba(16, 15, 15, 0.2);
    border-radius: 10px;
}


label.btn-icono-select.disabled svg {
    fill: #B6AFAF;
}