.pestana-group {
    position: relative;
}

.pestana-lista {
    list-style-type: none;
    display: flex;
    margin: unset;
    padding: unset;
    outline: unset;
}

.pestana-lista li {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 7px 30px;
    gap: 10px;
    height: 50px;
    min-width: 135px;
    border-bottom: 1px solid #024588;
    mix-blend-mode: normal;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: #024588;
    cursor: pointer;
}

.pestana-lista li:hover {
    background: #EBF2F9;
    border-bottom: 2px solid #024588;
    border-radius: 10px 10px 0px 0px;
}

.pestana-lista li:active {
    background: #D7E5F4;
    border: 2px solid #024588;
    border-radius: 10px 10px 0px 0px;
}

.pestana-lista .activo {
    background: #D7E5F4 !important;
    border-bottom: 3px solid #024588 !important;
    border-radius: 10px 10px 0px 0px !important;
}