.box-rt h1 {
    font-size: 28px !important;
    padding-bottom: 10px;
    padding-top: 10px;
}

.box-rt h2 {
    font-size: 23px !important;
    padding-bottom: 10px;
    padding-top: 10px;
}

.box-rt h3 {
    font-size: 18px !important;
    padding-bottom: 10px;
    padding-top: 10px;
}

.box-rt p {
    font-weight: 400;
    padding-bottom: 10px;
    padding-top: 10px;
}

.box-rt ul {
    list-style-type: disc !important;
}

.box-rt .is-empty {
    display: none;
}
