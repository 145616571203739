@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');
@import url(./importar/base.css);
@import url(./importar/scroll-bar.css);
@import url(./importar/menu.css);
@import url(./importar/body.css);
@import url(./importar/breadcrumb.css);
@import url(./importar/footer.css);
@import url(./importar/card.css);
@import url(./importar/buttons.css);
@import url(./importar/menu-lateral-fixed.css);
@import url(./importar/icono-box.css);
@import url(./importar/switch.css);
@import url(./importar/codigo.css);
@import url(./importar/colores.css);
@import url(./importar/chips.css);
@import url(./importar/checkbox.css);
@import url(./importar/snackbar.css);
@import url(./importar/grupo-de-botones.css);
@import url(./importar/buscador.css);
@import url(./importar/fab.css);
@import url(./importar/boton-icono.css);
@import url(./importar/insignias.css);
@import url(./importar/indicador-de-progreso.css);
@import url(./importar/hoja-inferior.css);
@import url(./importar/dialogos.css);
@import url(./importar/divisor.css);
@import url(./importar/listas.css);
@import url(./importar/hojas-laterales.css);
@import url(./importar/rastreador-de-progreso.css);
@import url(./importar/carril-de-navegacion.css);
@import url(./importar/campos-de-texto.css);
@import url(./importar/lista-desplegable.css);
@import url(./importar/pestanas.css);
@import url(./importar/barras-superiores.css);

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html,
body {
  height: 100%;
  overflow-x: hidden;
  max-width: 2500px;
  margin: 0 auto;
  padding: 0;
  background-color: #FAF9F9;
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

.wrapper {
  display: flex;
}

.column {
  height: 100%;
  overflow-y: hidden;
  padding: 0;
}

.header-box {
  height: auto;
  display: flex;
  padding: 70px 5% 60px;
  gap: 10%
}

.column-principal {
  display: flex;
  flex-wrap: wrap;
  /* padding: 80px 0 80px 5%; */
  padding: 80px 0 80px 0%;
  background-color: #FAF9F9;
  width: 100%;
  /* margin-left: 3%; */
  justify-content: space-between;
}

.column1 {
  /* flex: 0 0 220px; */
  position: fixed;
  background-color: #f2f2f2;
}

.column2 {
  width: 100%;
  margin-left: 202.5px;
  background-color: #FAF9F9;
}

.column-fixed {
  flex: 0 0 220px;
  background-color: #FAF9F9;
  height: 600px;
  position: relative;
  overflow-y: unset;
  display: flex;
}

@media (max-width: 1024px) {
  .column-fixed {
    display: none;
    /* Muestra el elemento cuando la pantalla tenga un ancho mayor o igual a 1024px */
  }
}

.h3-info {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #002846;
  mix-blend-mode: normal;
  margin-top: 25px;
}

.p-info {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: #002846;
  mix-blend-mode: normal;
}

.body-title {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 50px;
  color: #002846;
  margin-bottom: 10px;
}

.body-subtitle {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: #002846;
  margin-bottom: 25px;
  mix-blend-mode: normal;
  max-width: 95%;
}

.body-subtitle-2 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #002846;
  margin-bottom: 25px;
  mix-blend-mode: normal;
  max-width: 80%;
}