progress {
    background: #D7E5F4;
    border: none;
    border-radius: 10px;
    height: 6px;
    overflow: hidden;
    width: 100%;
}

progress::-webkit-progress-bar {
    background: #D7E5F4;
}

progress::-webkit-progress-value {
    background-color: #3D8DDD;
}

progress::-moz-progress-bar {
    background-color: #3D8DDD;
}

progress.completado::-webkit-progress-value {
    background-color: #27BB22;
}

progress.completado::-moz-progress-bar {
    background-color: #27BB22;
}

progress.cargando-barra-horizontal::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-color: #3D8DDD;
    animation: progress-bar 3s linear infinite;
}

@keyframes progress-bar {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(100%);
    }
}

.loader {
    border: 6px solid #D7E5F4;
    border-radius: 50%;
    width: 32px;
    height: 32px;
}

.loader-btn {
    border: 4px solid #ffffff;
    border-top: 4px solid #3D8DDD;
    border-right: 4px solid #3D8DDD;
    border-bottom: 4px solid #3D8DDD;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    animation: spin 1s linear infinite;
}

.loader-25 {
    border-top: 6px solid #3D8DDD;
    animation: spin 1s linear infinite;
}

.loader-50 {
    border-top: 6px solid #3D8DDD;
    border-right: 6px solid #3D8DDD;
    animation: spin 1s linear infinite;
}

.loader-75 {
    border-top: 6px solid #3D8DDD;
    border-right: 6px solid #3D8DDD;
    border-bottom: 6px solid #3D8DDD;
    animation: spin 1s linear infinite;
}

.loader-100 {
    border-top: 6px solid #27BB22;
    border-right: 6px solid #27BB22;
    border-bottom: 6px solid #27BB22;
    border-left: 6px solid #27BB22;
}

.loader-btn-100 {
    border-top: 4px solid #27BB22;
    border-right: 4px solid #27BB22;
    border-bottom: 4px solid #27BB22;
    border-left: 4px solid #27BB22;
}

.loader-btn-indeterminado {
    border: 4px solid #ffffff;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    animation: spinFull 2.5s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes spinFull {
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(180deg);
        border-top: 4px solid #3D8DDD;
    }

    50% {
        transform: rotate(360deg);
        border-top: 4px solid #3D8DDD;
        border-right: 4px solid #3D8DDD;
    }

    75% {
        transform: rotate(540deg);
        border-top: 4px solid #3D8DDD;
        border-right: 4px solid #3D8DDD;
        border-bottom: 4px solid #3D8DDD;
    }

    100% {
        transform: rotate(720deg);
        border-top: 4px solid #3D8DDD;
        border-right: 4px solid #3D8DDD;
        border-bottom: 4px solid #3D8DDD;
        border-left: 4px solid #3D8DDD;
    }
}