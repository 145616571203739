/* Style the list */
ul.breadcrumb {
    padding: 0;
    margin: 0;
    list-style: none;
    margin: 0;
}

/* Display list items side by side */
ul.breadcrumb li {
    display: inline;
    font-size: 18px;
}

/* Add a slash symbol (/) before/behind each list item */
ul.breadcrumb li+li:before {
    padding: 8px;
    color: black;
    content: "|";
}

/* Add a color to all links inside the list */
ul.breadcrumb li a {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #002846;
    mix-blend-mode: normal;
    text-decoration: none;
    padding-bottom: 2px;
    border-bottom: 1px solid #002846;

}

/* Add a color on mouse-over */
/* ul.breadcrumb li a:hover {
    
text-decoration: underline;
}

*/