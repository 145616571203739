#footer {
    background-color: #EBF2F9;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-start;
    padding: 60px 0 100px 0;
    gap: 0;
    margin-left: 200px;
}

@media (max-width: 1024px) {
    #footer {
        justify-content: center;
        padding: 60px 10% 100px 10%;
        gap: 45px;
    }
}

#footer p {
    margin: 0;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #100F0F;
    mix-blend-mode: normal;
}

#footer a {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #1669BC;
    text-decoration: none;
    padding-bottom: 2px;
    border-bottom: 1px solid #1669BC;
    mix-blend-mode: normal;
}