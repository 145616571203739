.menu-lateral-fixed {
    position: relative;
    top: 0;
    flex-direction: column;
    background-color: transparent;
    padding: 80px 0;
    /* overflow-y: hidden; */
    height: auto;
}

.menu-lateral-fixed.stuck {
    position: fixed;
    top: 50px;
}

.menu-lateral-fixed h4 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #002846;
    mix-blend-mode: normal;

}

.menu-lateral-fixed ul {
    padding: 0;
    margin: 0;
    list-style: none;
    margin: 0;
}

/* Display list items side by side */
.menu-lateral-fixed ul li {
    background: #FAF9F9;
    font-family: 'Montserrat';
    padding: 5px 2px;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 25px;
    /* color: #024588; */

    mix-blend-mode: normal;

}

/* Add a slash symbol (/) before/behind each list item */
/* ul.menu-lateral-fixed li+li:before {
    padding: 8px;
    color: black;
    content: "|";
} */

/* Add a color to all links inside the list */
.menu-lateral-fixed ul li a {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #024588;
    mix-blend-mode: normal;
    text-decoration: none;
    padding-bottom: 2px;
    border-bottom: 1px solid #024588;

}